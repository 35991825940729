.top.fixed.menu {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.16) !important;
  .header {
    font-weight: 100;
    padding: 0 15px !important;
  }
  .dropdown.item {
    box-shadow: none !important;
  }
}

.timer {
  position: fixed;
  z-index: 102;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  transform: translateX(-50%);
  font-size: 23px;
  font-weight: 100;

  span.time {
    vertical-align: top;
    font-size: 30px;
  }
}
