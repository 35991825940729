.footer-pusher {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  margin: $page-top-margin 0 10px;
  flex: 1;
}

footer {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}
