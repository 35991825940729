.arena {

  .header.row {
    .column {
      height: fit-content;
    }
    .ui.header {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }

  .cards.row {
    .column {
      height: 70vh;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center;

      .card > .content {
        text-align: left;
        padding: 0.5em 0.7em;
      }
    }
  }

  .end.row {
    .column {
      height: fit-content;

      textarea {
        font-size: small !important;
      }
    }
  }
}
