
.clock{
  border: none;
  background: transparent;
  height: 50px;
  width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;/*
  margin: auto;*/
  flex-direction: column;
  animation: clock 5s ease-in-out infinite;

  .cup{
    background: #a9a9a9;
    height: 30%;
    width: 30%;
    border-radius: 50% 50% 0 0/ 100% 100% 0 0;
    overflow: hidden;
  }

  .top:not(.menu){
    transform: rotate(180deg)  ;
  }

  .sand{
    background: #000;
    height: 150%;
    width: 150%;
    transform-origin: 0% 0%;
    animation: sand 5s linear infinite -2.5s;
  }

  .delayed{
    animation-delay: 0s;
  }


  @keyframes clock{
    0%{
      transform: rotate(0deg)
    }
    25%,50%{
      transform: rotate(180deg)
    }
    75%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }


  @keyframes sand{
    0%,100%{
      border-radius: 5%;
      transform: translate(35%,20%) rotate(45deg);
    }
    15%{
      border-radius:40%;
      transform: translate(0%,35%) rotate(-65deg) scale(1.5,1.5);
    }
    25%{
      border-radius:20%;
      transform: translate(-15%,35%) rotate(-90deg) scale(1.2,1.2);
    }
    50%{
      border-radius:5%;
      transform: translate(0%,0%) rotate(-90deg) scale(1,1);
      opacity: 1;
    }
    50.01%,64.99%{
      opacity: 0;
    }
    65%{
      border-radius:35%;
      transform: translate(20%,50%) rotate(30deg);
      opacity: 1;
    }
    75%{
      border-radius:10%;
      transform: translate(35%,40%) rotate(45deg);
    }
  }
}

